<template>
  <VContainer fluid fill-height class="loader-redirect">
    <img
      class="logo-container__logo"
      src="@/assets/images/socotec-logo-alpha.png"
      height="200px"
    />
  </VContainer>
</template>

<script>
// import reportingClient from "@/setup/axios";
import { mapMutations } from "vuex";
import { socioGrpcClient } from "@/setup/socioGrpcClient";

export default {
  name: "AsanaCallback",
  data() {
    return {};
  },
  async mounted() {
    try {
      await this.getToken();
      this.$router.push("/");
    } catch (err) {
      console.error(err);
      this.$router.push({ name: "DisplayError", params: { error: err } });
    }
  },
  methods: {
    ...mapMutations("asanaCredentials", ["setAsanaCredentials"]),
    async getToken() {
      const request_data = {
        code: this.$route.query.code,
        clientId: process.env.VUE_APP_OAUTH_CLIENT_ID,
        grantType: "authorization_code",
        redirectUri: `${window.location.origin}/asana-callback`
      };
      const request = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.reporting_back.oauthtoken.AsanaAuthentificationRequest,
        request_data
      );
      const response = await socioGrpcClient.reporting_back.oauthtoken.OAuthTokenControllerPromiseClient.getOAuthToken(
        request,
        {}
      );
      let responseObject = response.toObject();
      /* Transforming data Struct into a Javascript Object */
      responseObject.data = response.getData().toJavaScript();
      this.setAsanaCredentials(responseObject);
      return response.data;
    }
  }
};
</script>

<style lang="scss" scoped>
.loader-redirect {
  height: 100vh;
  background: #212432;

  .logo-container__logo {
    margin: auto;
    opacity: 1;
    animation: opacityblink 2s infinite;
  }
}
@keyframes opacityblink {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
</style>
